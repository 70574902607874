import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import OrganizationCatalogType from './components/OrganizationCatalogType';
import LanguageProp from '../forms/Address/CountryProp';

// import ConfigsContext from '../../contextProviders/ConfigsContext';

function CatalogTypesDragList(props) {
  // TODO: USE CONTEXT WHEN MIGRATE ORGANIZATION CONFIGS TO REACT JS
  // const { organization } = useContext(ConfigsContext);
  // const { marketCatalogTypesList = [] } = organization || {};

  const {
    marketCatalogTypesList = [],
    orgLanguages,
    defaultLanguage,
    t,
    onChange,
  } = props;

  const [catalogTypes, setCatalogTypes] = useState(
    marketCatalogTypesList
      ?.map(c => ({
        name: c.name,
        type: c.type,
        translations: c.translations,
        position: c.position,
        isActive: c.isActive,
      }))
      .sort((a, b) => a.position - b.position) || [],
  );
  const [dragged, setDragged] = useState(null);

  const handleDragStart = (e, name) => {
    setDragged(name);
    e.dataTransfer.effectAllowed = 'move';
  };

  const handleDragOver = e => {
    e.preventDefault();
  };

  const handleDrop = (e, targetIndex) => {
    e.preventDefault();
    if (dragged === null) {
      return;
    }
    const itemToMove = catalogTypes.find(
      catalogType => catalogType.name === dragged,
    );

    if (itemToMove) {
      const newItems = [...catalogTypes];
      const currentIndex = newItems.indexOf(itemToMove);

      newItems.splice(currentIndex, 1); // Remove the item from its current position
      newItems.splice(targetIndex, 0, itemToMove); // Insert it at the new position
      setCatalogTypes(newItems.map((c, index) => ({ ...c, position: index })));
    }
    setDragged(null);
  };

  const handleChange = useCallback(
    (field, targetIndex, code) => value => {
      switch (field) {
        case 'languages':
          setCatalogTypes(prevCatalogTypes =>
            prevCatalogTypes.map((cType, index) => {
              const catalogType = cType;
              if (index === targetIndex) {
                catalogType.translations[code] = value;
              }
              return catalogType;
            }),
          );
          break;
        case 'isActive':
          setCatalogTypes(prevCatalogTypes =>
            prevCatalogTypes.map((cType, index) => {
              const catalogType = cType;
              if (index === targetIndex) {
                catalogType.isActive = !catalogType.isActive;
              }
              return catalogType;
            }),
          );
          break;
        default:
          break;
      }
    },
    [setCatalogTypes],
  );

  useEffect(() => {
    if (onChange) {
      onChange(catalogTypes);
    }
  }, [catalogTypes, onChange]);

  return (
    <>
      <ul className="organizationCatalogTypesList">
        {catalogTypes.map((catalogType, index) => (
          <OrganizationCatalogType
            key={catalogType.name}
            catalogType={catalogType}
            handleDragStart={handleDragStart}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            dragged={dragged}
            index={index}
            onChange={handleChange}
            orgLanguages={orgLanguages}
            defaultLanguage={defaultLanguage}
            t={t}
          />
        ))}
      </ul>
      <input
        type="hidden"
        id="marketCatalogTypesList"
        name="marketCatalogTypesList"
        value={JSON.stringify(catalogTypes)}
      />
    </>
  );
}

CatalogTypesDragList.propTypes = {
  t: PropTypes.func.isRequired,
  marketCatalogTypesList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      translations: PropTypes.object,
      isActive: PropTypes.bool,
    }),
  ).isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  orgLanguages: PropTypes.arrayOf(PropTypes.shape(LanguageProp.propType))
    .isRequired,
  onChange: PropTypes.func,
};

CatalogTypesDragList.defaultProps = {
  onChange: null,
};

export default CatalogTypesDragList;

/**
 {catalogType?.name === 'placeholder' && (
 <li key="placeholder" className="list-item drop-target">
 → Drop Here ←
 </li>
 )}
 */
