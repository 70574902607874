import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '../../forms/FormGroup';
import FormLabel from '../../forms/FormLabel';
import translate from '../../translate';
import LanguageProp from '../../forms/Address/CountryProp';
import CustomToggleSwitch from '../../forms/inputs/CustomToggleSwitch';

function Icon() {
  return (
    <svg
      width="15"
      height="23"
      viewBox="0 0 15 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        /* eslint-disable-next-line max-len */
        d="M2.5 13.75C3.88071 13.75 5 12.6307 5 11.25C5 9.86929 3.88071 8.75 2.5 8.75C1.11929 8.75 0 9.86929 0 11.25C0 12.6307 1.11929 13.75 2.5 13.75Z"
        fill="#7D98AE"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M2.5 5C3.88071 5 5 3.88071 5 2.5C5 1.11929 3.88071 0 2.5 0C1.11929 0 0 1.11929 0 2.5C0 3.88071 1.11929 5 2.5 5Z"
        fill="#7D98AE"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M2.5 22.5C3.88071 22.5 5 21.3807 5 20C5 18.6193 3.88071 17.5 2.5 17.5C1.11929 17.5 0 18.6193 0 20C0 21.3807 1.11929 22.5 2.5 22.5Z"
        fill="#7D98AE"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M12.5 13.75C13.8807 13.75 15 12.6307 15 11.25C15 9.86929 13.8807 8.75 12.5 8.75C11.1193 8.75 10 9.86929 10 11.25C10 12.6307 11.1193 13.75 12.5 13.75Z"
        fill="#7D98AE"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M12.5 5C13.8807 5 15 3.88071 15 2.5C15 1.11929 13.8807 0 12.5 0C11.1193 0 10 1.11929 10 2.5C10 3.88071 11.1193 5 12.5 5Z"
        fill="#7D98AE"
      />
      <path
        /* eslint-disable-next-line max-len */
        d="M12.5 22.5C13.8807 22.5 15 21.3807 15 20C15 18.6193 13.8807 17.5 12.5 17.5C11.1193 17.5 10 18.6193 10 20C10 21.3807 11.1193 22.5 12.5 22.5Z"
        fill="#7D98AE"
      />
    </svg>
  );
}

Icon.propTypes = {};

Icon.defaultProps = {};

function OrganizationCatalogType(props) {
  const {
    t,
    catalogType,
    handleDragStart,
    dragged,
    index,
    onDragOver,
    onDrop,
    onChange,
    orgLanguages,
    defaultLanguage,
  } = props;

  // TODO: USE CONTEXT WHEN MIGRATE ORGANIZATION CONFIGS TO REACT JS
  // const { defaultLanguage, orgLanguages: languages } = useContext(
  //   OrganizationLanguageContext,
  // );

  const { translations, name, type } = catalogType;

  const formByLanguage = orgLanguages.map(language => {
    const isDefault = defaultLanguage === language.code;
    const previousData = isDefault
      ? translations?.[defaultLanguage]
      : translations?.[language.code];
    return (
      <FormGroup key={`name-${language.code}`} className="input-wrapper">
        <>
          {isDefault ? (
            <FormLabel
              id="catalog-name"
              label={t(`catalogTypes.${type}`)}
              className="inputLabel"
            />
          ) : (
            <div className="inputLabel" />
          )}
          <div className="input-group m-input-group inputWrapper">
            <div className="input-group-prepend">
              <span className="input-group-text">
                <img src={language.flag.thumb} alt={language.name} />
              </span>
            </div>
            <input
              className="form-control m-input"
              id={isDefault ? 'catalog-name' : `catalog-name-${language.code}`}
              type="text"
              placeholder={t('product_name_Placeholder')}
              name={
                isDefault ? 'catalogName' : `translations-name-${language.code}`
              }
              value={previousData || ''}
              onChange={event =>
                onChange(
                  'languages',
                  index,
                  language.code,
                )(event.currentTarget.value)
              }
            />
          </div>
        </>
      </FormGroup>
    );
  });

  return (
    <li
      key={catalogType.name}
      className={`catalogType-drag-card ${name === dragged ? 'dragged' : ''}`}
      draggable="true"
      onDragStart={e => handleDragStart(e, catalogType.name)}
      onDragOver={e => {
        e.preventDefault(); // Prevent the default behavior
        onDragOver(e, index);
      }}
      onDrop={e => onDrop(e, index)}
    >
      <div className="draggable">
        <Icon />
      </div>
      <div className="input-container">{formByLanguage}</div>
      <div className="isActive-container">
        <CustomToggleSwitch
          t={t}
          id={`${catalogType?.name}_switch`}
          name={`${catalogType?.name}_switch`}
          label={`${t('active')}?`}
          value={catalogType.isActive}
          onChange={onChange('isActive', index)}
          className="switch-wrapper"
        />
      </div>
    </li>
  );
}

OrganizationCatalogType.propTypes = {
  t: PropTypes.func.isRequired,
  catalogType: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    isActive: PropTypes.bool,
    translations: PropTypes.shape({}),
  }).isRequired,
  handleDragStart: PropTypes.func.isRequired,
  onDragOver: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  dragged: PropTypes.string,
  defaultLanguage: PropTypes.string.isRequired,
  orgLanguages: PropTypes.arrayOf(PropTypes.shape(LanguageProp.propType))
    .isRequired,
};

OrganizationCatalogType.defaultProps = {
  dragged: null,
};

export default translate(OrganizationCatalogType);
