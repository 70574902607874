import React from 'react';
import PropTypes from 'prop-types';

/**
 * Checkbox component wrapping the label -> input -> span pattern
 */
export default function CustomToggleSwitch({
  t,
  id,
  name,
  label,
  value,
  onChange,
  disabled,
  hint,
  toggleWrapperClass,
  hintClass,
  labelClasses,
  toggleClasses,
}) {
  return (
    <div className={`customToggleSwitch ${toggleWrapperClass}`}>
      <label htmlFor={id} className={` ${labelClasses}`}>
        {label}
      </label>
      <span className={`m-switch m-switch--sm ${toggleClasses}`}>
        <label
          className={`action-item switch-label no-margin col-12 ${toggleWrapperClass}`}
        >
          <input
            id={id}
            type="checkbox"
            name={name}
            defaultChecked={value}
            onChange={event => {
              const isChecked =
                typeof event.target?.checked !== 'undefined'
                  ? event.target.checked
                  : event;
              if (onChange) {
                onChange(isChecked);
              }
            }}
            readOnly={onChange ? null : true}
            disabled={disabled}
          />
          <span />
        </label>
        <span data-yes={t('yes')} data-no={t('no')} />
        {hint && (
          <span className={`m-form__help col-12 ${hintClass}`}>{hint}</span>
        )}
      </span>
    </div>
  );
}

CustomToggleSwitch.propTypes = {
  t: PropTypes.func.isRequired,
  /** Input html id */
  id: PropTypes.string.isRequired,
  /** Input html name */
  name: PropTypes.string,
  /** Label text shown */
  label: PropTypes.string,
  /** Checkbox input value, true or false */
  value: PropTypes.bool.isRequired,
  /** Change handler, should negate the previous value. If not given the component will be read only */
  onChange: PropTypes.func,
  /** String of class names to be passed on to the wrapping <label /> */
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  toggleWrapperClass: PropTypes.string,
  hintClass: PropTypes.string,
  labelClasses: PropTypes.string,
  toggleClasses: PropTypes.string,
};

CustomToggleSwitch.defaultProps = {
  name: '',
  label: '',
  onChange: null,
  disabled: false,
  hint: null,
  toggleWrapperClass: '',
  hintClass: '',
  labelClasses: '',
  toggleClasses: '',
};
